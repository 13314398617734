import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../../components/layout";
import { Header } from "../../components/03_organisms/header";
import { Footer } from "../../components/03_organisms/footer";
import { Bio } from "../../components/03_organisms/bio";
import ogPic from "../../images/ogp.png";

import styles from "./style.module.css";

const Contact = ({ data }) => {
  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        title={data.site.siteMetadata.title}
        meta={[
          { name: "description", content: data.site.siteMetadata.description },
          { name: "keywords", content: data.site.siteMetadata.keywords },
          { property: "og:title", content: data.site.siteMetadata.title },
          {
            property: "og:description",
            content: data.site.siteMetadata.description,
          },
          { property: "og:url", content: data.site.siteMetadata.siteUrl },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: data.site.siteMetadata.title },
          { property: "twitter:card", content: "summary_large_image" },
          {
            property: "twitter:site",
            content: data.site.siteMetadata.twitterUsername,
          },
          {
            property: "google-site-verification",
            content: data.site.siteMetadata.google_site_verification,
          },
          {
            property: "og:image",
            content: data.site.siteMetadata.siteUrl + ogPic,
          },
          { httpEquiv: "expires", content: "43200" },
          //{ property: 'og:image', content: mushiIcon },
        ]}>
        <script
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <Header isHome={true} />
      <div id="main-contents">
        <div id="desc-contents">
          <img
            width="900px"
            src={ogPic}
            className="desc-item-image"
            alt="お問い合わせ"
          />
          <h1 className="desc-item-title">問い合わせフォーム</h1>
          <form
            className={styles.contactForm}
            name="contact"
            method="POST"
            data-netlify-recaptcha="true"
            data-netlify="true">
            <p>
              <label className={styles.contactFormLabel}>
                <div>名前</div>
                <input
                  className={styles.contactFormInput}
                  type="text"
                  name="name"
                />
              </label>
            </p>
            <p>
              <label className={styles.contactFormLabel}>
                <div>Email</div>
                <input
                  className={styles.contactFormInput}
                  type="email"
                  name="email"
                />
              </label>
            </p>
            <p>
              <label>
                <div>要件</div>
                <div className={styles.contactFormSelectRap}>
                  <select className={styles.contactFormSelect} name="role">
                    <option value="none">選択してください</option>
                    <option value="system">システム構築</option>
                    <option value="app">スマホアプリ制作</option>
                    <option value="web">Webサイト制作</option>
                    <option value="mentor">プログラミングメンター</option>
                    <option value="other">その他</option>
                  </select>
                </div>
              </label>
            </p>
            <p>
              <label>
                <div>詳細</div>
                <textarea
                  className={styles.contactFormInput}
                  name="message"></textarea>
              </label>
            </p>
            <div data-netlify-recaptcha="true"></div>
            <div className={styles.contactFormButtonArea}>
              <button className={styles.contactFormButton} type="submit">
                送信
              </button>
            </div>
          </form>
        </div>
        <Bio />
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query {
    site: site {
      siteMetadata {
        title
        keywords
        description
        siteUrl
        twitterUsername
        google_site_verification
      }
    }
  }
`;
export default Contact;
